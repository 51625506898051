//
// popover.js
// Theme module
//

import { Popover } from 'bootstrap';

$( function() {

  const popovers = document.querySelectorAll('[data-bs-toggle="popover"]');

  popovers.forEach(popover => {
    new Popover(popover, { html: true });
  });

});