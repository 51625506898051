window.modal_model_change = function(model_kind){
  if (model_kind == 'banner'){
    $("#div-popup-model-banner").show();
    $("#div-popup-model-custom").hide();
  } else if (model_kind == 'custom') {
    $("#div-popup-model-banner").hide();
    $("#div-popup-model-custom").show();
  }
}

window.modal_custom_header_kind_change = function(custom_header_kind){
  if (custom_header_kind == 'none'){
    $("#div-popup-custom-header-kind-icon").hide();
    $("#div-popup-custom-header-kind-countdown").hide();
  } else if (custom_header_kind == 'icon') {
    $("#div-popup-custom-header-kind-icon").show();
    $("#div-popup-custom-header-kind-countdown").hide();
  } else if (custom_header_kind == 'countdown') {
    $("#div-popup-custom-header-kind-icon").hide();
    $("#div-popup-custom-header-kind-countdown").show();
  }
}

window.modal_custom_cta_kind_change = function(custom_cta_kind){
  if (custom_cta_kind == 'none'){
    $("#div-popup-custom-cta-kind-button").hide();
  } else if (custom_cta_kind == 'newsletter') {
    $("#div-popup-custom-cta-kind-button").hide();
  } else if (custom_cta_kind == 'button') {
    $("#div-popup-custom-cta-kind-button").show();
  }
}
