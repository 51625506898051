$(function() {

  $('#orders-select-all').click(function() {
    $('#orders-batch-actions').show();
    var checkedStatus = this.checked;
    $('#table-orders tbody tr').find('td.td-select :checkbox').each(function() {
      $(this).prop('checked', checkedStatus);
    });
    if ($('#table-orders tbody tr td.td-select input[type=checkbox]:checked').length == 0){
      $('#selected-orders-count').text("Nenhum pedido selecionado");
    }
    if ($('#table-orders tbody tr td.td-select input[type=checkbox]:checked').length == 1){
      $('#selected-orders-count').text("1 pedido selecionado");
    }
    if ($('#table-orders tbody tr td.td-select input[type=checkbox]:checked').length > 1){
      $('#selected-orders-count').text(""+ $('#table-orders tbody tr td.td-select input[type=checkbox]:checked').length + " pedidos selecionados");
    }
  });

  $('td.td-select :checkbox').click(function() {
    $('#orders-batch-actions').show();
    if ($('#table-orders tbody tr td.td-select input[type=checkbox]:checked').length == 0){
      $('#selected-orders-count').text("Nenhum pedido selecionado");
    }
    if ($('#table-orders tbody tr td.td-select input[type=checkbox]:checked').length == 1){
      $('#selected-orders-count').text("1 pedido selecionado");
    }
    if ($('#table-orders tbody tr td.td-select input[type=checkbox]:checked').length > 1){
      $('#selected-orders-count').text(""+ $('#table-orders tbody tr td.td-select input[type=checkbox]:checked').length + " pedidos selecionados");
    }
  });

});
