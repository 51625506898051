window.open_modal_add_item_to_cart = function(sid,pid){
  $('#modal-add-item-to-cart-submit').removeClass("disabled").text("Adicionar ao pedido");
  $("#table-modal-product-variations,#modal-add-item-to-cart-alert,#modal-add-item-to-cart-submit").hide();
  $("#ajax-loading-product-variations").show();
  $("#table-modal-product-variations tbody").html('');
  $("#modal-add-item-to-cart h4").text("Adicionar ao pedido");
  $('#modal-add-item-to-cart').modal('show');
  $.getScript("/a/stores/"+sid+"/cart/items/"+pid);
  return false;
}

window.open_modal_add_item_to_cart_submit = function(sid){
  $('#modal-add-item-to-cart-submit').addClass('disabled').text('Aguarde...');
  $.ajax({
    type: 'post',
    data: {
      qty: 1,
      vid: $("input:radio[name='table-modal-product-variations-item-id']:checked").val()
    },
    dataType: 'script',
    complete: function(request) {
    },
    url: "/a/stores/"+sid+"/cart/items"
  })

}

window.variations_focus_to_apply_to_all = function(field_name){
  $("#table-product-variations .th-"+field_name).addClass("th-selected");
  $("#table-product-variations .td-"+field_name+".td-normal-child").addClass("td-selected");
  $("#table-product-variations .td-"+field_name+".td-last-child").addClass("td-last-selected");
}

window.variations_blur_to_apply_to_all = function(field_name){
  $("#table-product-variations .th-"+field_name).removeClass("th-selected");
  $("#table-product-variations .td-"+field_name+".td-normal-child").removeClass("td-selected");
  $("#table-product-variations .td-"+field_name+".td-last-child").removeClass("td-last-selected");
}


window.variations_apply_to_all_fields = function(field_price, field_old_price, field_stock, field_sku, count){
  let price = $("#"+field_price).val();
  let old_price = $("#"+field_old_price).val();
  let stock = $("#"+field_stock).val();
  let sku = $("#"+field_sku).val();

  for (var i=0; i < count; i++){
    if (price != ""){
      $("#admin_product_product_variations_attributes_"+i+"_price").val(price);
    }
    if (old_price != ""){
      $("#admin_product_product_variations_attributes_"+i+"_old_price").val(old_price);
    }
    if (stock != ""){
      $("#admin_product_product_variations_attributes_"+i+"_stock").val(stock);
    }
    if (sku != ""){
      $("#admin_product_product_variations_attributes_"+i+"_sku").val(sku);
    }
  }

  $("#"+field_price).val("");
  $("#"+field_old_price).val("");
  $("#"+field_stock).val("");
  $("#"+field_sku).val("");

  $('#modal-form').modal('show');
}

window.select_all_to_delete = function(){
  $('#table-product-variations tbody tr').find('td.td-variation-remove :checkbox').each(function() {
    var checkedStatus = this.checked;
    if (checkedStatus){
      $(this).prop('checked', false);
    } else {
      $(this).prop('checked', true);
    }
  });
}

window.sku_code_generator = function(sku_field) {
  var text = "";
  var possible = "ABCDEFGHJKLMNPQRSTUVWXYZ23456789";
  for( var i=0; i < 6; i++ )
      text += possible.charAt(Math.floor(Math.random() * possible.length));
  $('#'+sku_field).val(text);
}

$( function() {

  $('#products-cancel-batch-actions').click(function(event) {

    event.preventDefault();

    $('#products-batch-actions').hide();

    var checkedStatus = false;

    $("#products-select-all").prop("checked", checkedStatus);

    $('#table-products tbody tr').find('td.td-select :checkbox').each(function() {
      $(this).prop('checked', checkedStatus);
    });

    $('#selected-products-count').text("Nenhum produto selecionado");

  });

  $('#products-select-all').click(function() {
    $('#products-batch-actions').show();
    var checkedStatus = this.checked;
    $('#table-products tbody tr').find('td.td-select :checkbox').each(function() {
      $(this).prop('checked', checkedStatus);
    });
    if ($('#table-products tbody tr td.td-select input[type=checkbox]:checked').length == 0){
      $('#selected-products-count').text("Nenhum produto selecionado");
    }
    if ($('#table-products tbody tr td.td-select input[type=checkbox]:checked').length == 1){
      $('#selected-products-count').text("1 produto selecionado");
    }
    if ($('#table-products tbody tr td.td-select input[type=checkbox]:checked').length > 1){
      $('#selected-products-count').text(""+ $('#table-products tbody tr td.td-select input[type=checkbox]:checked').length + " produtos selecionados");
    }
  });


  $('td.td-select :checkbox').click(function() {
    $('#products-batch-actions').show();
    if ($('#table-products tbody tr td.td-select input[type=checkbox]:checked').length == 0){
      $('#selected-products-count').text("Nenhum produto selecionado");
    }
    if ($('#table-products tbody tr td.td-select input[type=checkbox]:checked').length == 1){
      $('#selected-products-count').text("1 produto selecionado");
    }
    if ($('#table-products tbody tr td.td-select input[type=checkbox]:checked').length > 1){
      $('#selected-products-count').text(""+ $('#table-products tbody tr td.td-select input[type=checkbox]:checked').length + " produtos selecionados");
    }
  });

  $('#admin_product_tags').tagsInput({
     'height':'auto',
     'width':'100%',
     'interactive':true,
     'defaultText':'',
     'removeWithBackspace' : true,
     'minChars' : 3,
     'maxChars' : 20, // if not provided there is no limit
     'placeholderColor' : '#666666'
  });

  tinymce.remove("#admin_product_product_description_attributes_description");

  tinymce.init({
    menubar: false,
    selector: "#admin_product_product_description_attributes_description",
    language: 'pt_BR',
    height: 300,
    skin: false,
    content_css: false,
    plugins: [
      'advlist autolink lists link image charmap hr',
      'visualblocks code',
      'media save table directionality',
      'emoticons paste textpattern imagetools'
    ],
    toolbar1: 'styleselect | forecolor backcolor | bold italic | alignleft aligncenter alignjustify | bullist numlist | link image media table | code',
    paste_as_text: false,
    paste_word_valid_elements: "b,strong,i,em,h1,h2",
    statusbar: false,
    content_style: 'p { font-size: 16px }'
  });


});

