window.campaign_has_filter = function() {
  if ($('#admin_campaign_gender_m').prop('checked') ||
         $('#admin_campaign_gender_f').prop('checked') ||
         $('#admin_campaign_states').val()!=undefined ||
         $('#admin_campaign_cities').val()!=undefined ||
         $('#admin_campaign_total_spent_greater_than').val()!="" ||
         $('#admin_campaign_total_spent_less_than').val()!="" ||
         $('#admin_campaign_total_purchases_items').val()!="" ||
         $('#admin_campaign_total_purchases').val()!="" ||
         $('#admin_campaign_total_purchases').val()!="" ||
         $('#admin_campaign_abandoned_cart').val()=='1' ||
         $('#admin_campaign_didnt_payed').val()=='1' ||
         $('#admin_campaign_bought_in_categories').val()!=undefined ||
         $('#admin_campaign_bought_the_products').val()!=undefined ||
         $('#admin_campaign_joined_since').val()!=""){
    $('#row-all').hide();
  } else {
    $('#row-all').show();
  }
}
