import swal from 'sweetalert2'

$.rails.allowAction = function(element) {

  var message;
  message = element.data('confirm');

  if (message == null) return true;

  if ($.rails.fire(element, 'confirm')) {

    const swalWithBootstrap = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-default margin-left-sm'
      },
      buttonsStyling: false
    })

    swalWithBootstrap.fire({
      text: message,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    })
    .then((result) => {
      if (result.value) {

        var oldAllowAction;
        oldAllowAction = $.rails.allowAction;
        $.rails.allowAction = function() {
          return true;
        };
        element.trigger('click');
        return $.rails.allowAction = oldAllowAction;

      }
    });

  };

};