$( function() {

  tinymce.remove("#admin_model_description_description");

  tinymce.init({
    menubar: false,
    selector: "#admin_model_description_description",
    language: 'pt_BR',
    height: 600,
    skin: false,
    content_css: false,
    plugins: [
      'advlist autolink lists link image charmap hr',
      'visualblocks code',
      'media save table directionality',
      'emoticons paste textpattern imagetools'
    ],
    toolbar1: 'styleselect | forecolor backcolor | bold italic | alignleft aligncenter alignjustify | bullist numlist | link image media table | code',
    paste_as_text: false,
    paste_word_valid_elements: "b,strong,i,em,h1,h2",
    statusbar: false,
    content_style: 'p { font-size: 16px }'
  });

});

