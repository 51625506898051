window.coupon_kind_change = function(coupon_kind) {
  if (coupon_kind == 's'){
    $("#div-coupon-percent").hide();
    $("#div-coupon-fixed").hide();
  } else if (coupon_kind == 'f') {
    $("#div-coupon-percent").hide();
    $("#div-coupon-fixed").show();
  } else {
    $("#div-coupon-percent").show();
    $("#div-coupon-fixed").hide();
  }
}

window.coupon_code_generator = function() {
  var text = "";
  var possible = "ABCDEFGHJKLMNPQRSTUVWXYZ23456789";
  for( var i=0; i < 6; i++ )
      text += possible.charAt(Math.floor(Math.random() * possible.length));
  $('#admin_coupon_code').val(text);
}

