window.select_zip_range = function(zip1, zip2, field1, field2, days_field, days_default_value) {
  $("#"+field1).val(zip1);
  $("#"+field2).val(zip2);
  $("#"+days_field).val(days_default_value);
  $("#modal-ceps").modal('hide');
}

window.change_zip_region = function(option){
  $('#admin_zip_range_zip_start').val($('#zip_regions').find(':selected').data('zip-start'));
  $('#admin_zip_range_zip_end').val($('#zip_regions').find(':selected').data('zip-end'));
}