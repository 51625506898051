/*window.toggle_progressive_discount_fields = function(){
  alert($(this).is(':checked'))
}*/

$( function() {

  // copia valor para tabela
  $(".discount-percent-field-alone").bind('keyup change click', function () {
    $('.discount-percent-field-progressive').val(this.value);
  });

  // copia valor para alone
  $(".discount-percent-field-progressive").bind('keyup change click', function () {
    $('.discount-percent-field-alone').val(this.value);
  });

  $('#checkbox-progressive-discount').click(function() {

    // ativar desconto progressivo
    if (this.checked){
      $("#discount-alone").hide();
      $("#progressive-discount-fields").show();

      // valor padrao
      $("#admin_promotion_discount_quantity1").val("1");
      $("#admin_promotion_discount_quantity2").val("5");
      $("#admin_promotion_discount_quantity3").val("10");
      $("#admin_promotion_discount_quantity4").val("");
      $("#admin_promotion_discount_quantity5").val("");
      $('.discount-percent-field-alone').val("5");
      $('.discount-percent-field-progressive').val("5");
      $("#admin_promotion_discount_percent2").val("20");
      $("#admin_promotion_discount_percent3").val("30");
      $("#admin_promotion_discount_percent4").val("");
      $("#admin_promotion_discount_percent5").val("");

    // desativar desconto progressivo
    } else {
      $("#discount-alone").show();
      $("#progressive-discount-fields").hide();

      // limpar geral
      $("#admin_promotion_discount_quantity1").val("1");
      $("#admin_promotion_discount_quantity2").val("");
      $("#admin_promotion_discount_quantity3").val("");
      $("#admin_promotion_discount_quantity4").val("");
      $("#admin_promotion_discount_quantity5").val("");
      $('.discount-percent-field-alone').val("10");
      $('.discount-percent-field-progressive').val("10");
      $("#admin_promotion_discount_percent2").val("");
      $("#admin_promotion_discount_percent3").val("");
      $("#admin_promotion_discount_percent4").val("");
      $("#admin_promotion_discount_percent5").val("");
    }

  });


  $('#products-modal-select-all').click(function() {
    var checkedStatus = this.checked;
    $('#table-products-modal tbody tr').find('td.td-select :checkbox').each(function() {
      $(this).prop('checked', checkedStatus);
    });
  });

});

