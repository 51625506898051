window.open_modal_create_feedback = function(){
  $('#modal-create-feedback .btn-primary').removeClass('disabled').text('Enviar sugestão');
  $('#modal-create-feedback').modal('show');
  return false;
}

$( document ).ready(function() {
  $("#modal-create-feedback .btn-primary").on( "click", function( event ) {
    $('#modal-create-feedback .btn-primary').addClass('disabled').text('Enviando...');
    $("form#new_superadmin_feedback").submit();
  });
});