$( function() {
  $(".correios_addresses_zip_btn").on("click",function(event){
    event.preventDefault();
    ajax_correios_addresses_search();
  });
  $(".placeholder_correios_addresses_zip").on("keypress",function(event){
    if (event.keyCode == 13){
      event.preventDefault();
      ajax_correios_addresses_search();
      $.rails.disableElement($('a.correios_addresses_zip_btn'));
    }
  });
});

window.ajax_correios_addresses_search = function() {
  $.getScript("/a/correios_addresses/"+$(".placeholder_correios_addresses_zip").val());
}

