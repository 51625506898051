import 'controllers'

//import Rails from "@rails/ujs"

// Make accessible for Electron and Mobile adapters
//window.Rails = Rails

require("@rails/activestorage").start()
// require("channels")
// require("trix")
// require("@rails/actiontext")

// Usado para criar campanhas
const Handlebars = require("handlebars")
window.Handlebars = Handlebars

// css
import 'stylesheets/admin/theme.scss'

import '../src/shared/hashcash.js'
import '../src/shared/jquery-ujs.js'
import '../src/shared/active_storage_direct_upload.js'
import '../src/shared/auto_numeric_min.js'
import '../src/shared/sweetalert_confirm_modal.js'
import '../src/shared/jquery-ui.min.js'
import '../src/shared/offline.min.js'

import '../src/admin/campaigns.js'
import '../src/admin/correios_addresses.js'
import '../src/admin/correios_services.js'
import '../src/admin/coupons.js'
import '../src/admin/custom_shipments.js'
import '../src/admin/feedbacks.js'
import '../src/admin/modal_icons.js'
import '../src/admin/modals.js'
import '../src/admin/model_descriptions.js'
import '../src/admin/newsletter_forms.js'
import '../src/admin/orders.js'
import '../src/admin/pace.min.js'
import '../src/admin/pages.js'
import '../src/admin/posts.js'
import '../src/admin/products.js'
import '../src/admin/promotions.js'
import '../src/admin/select2-410.full.min.js'
import '../src/admin/select2-410.pt-BR.js'
import '../src/admin/slug.js'
import '../src/admin/socials.js'
import '../src/admin/terms.js'
import '../src/admin/jquery.tagsinput.min.js'
import '../src/admin/ptjs.min.js'

import '../src/admin/theme/theme.js'

require("chartkick")
require("chart.js")

// tinymce
import tinymce from 'tinymce/tinymce'
import 'tinymce-i18n/langs5/pt_BR'
import 'tinymce/icons/default'
import 'tinymce/themes/silver'
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/link'
import 'tinymce/plugins/image'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/hr'
import 'tinymce/plugins/visualblocks'
import 'tinymce/plugins/code'
import 'tinymce/plugins/media'
import 'tinymce/plugins/save'
import 'tinymce/plugins/table'
import 'tinymce/plugins/contextmenu'
import 'tinymce/plugins/directionality'
import 'tinymce/plugins/emoticons'
import 'tinymce/plugins/emoticons/js/emojis'
import 'tinymce/plugins/paste'
import 'tinymce/plugins/textcolor'
import 'tinymce/plugins/colorpicker'
import 'tinymce/plugins/textpattern'
import 'tinymce/plugins/imagetools'

//Rails.start()

$( function() {

  $('input[data-role=money]').autoNumeric('init', { aSep: '', aDec: ',' } );

  const popoverTriggerList = document.querySelectorAll('[data-toggle="popover"]')
  const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl, { html: true }))

  console.log('boxloja loaded v8');

});
